<template>
    <div>
        <el-dialog
                :title="title+typeList[inputForm.menuType]"
                :close-on-click-modal="false"
                :visible.sync="visible" class="dialogForm">
            <el-form size="small" :model="inputForm" v-loading="loading" :class="method==='view'?'readonly':''"
                     :disabled="method==='view'" :rules="dataRule" ref="inputForm" @keyup.enter.native="doSubmit()"
                     label-width="120px" @submit.native.prevent>
                <el-form-item label="菜单类型" prop="menuType">
                    <el-radio-group v-model="inputForm.menuType">
                        <el-radio v-for="(type, index) in typeList" :label="index.toString()" :key="index">{{ type }}
                        </el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="上级菜单" prop="parentId">
                    <SelectTree
                            ref="menuParentTree"
                            :props="{
                value: 'id',             // ID字段名
                label: 'name',         // 显示名称
                children: 'children'    // 子级字段名
              }"
                            :data="menuList"
                            :value="inputForm.parentId"
                            :clearable="true"
                            :accordion="true"
                            @getValue="(value) => {inputForm.parentId=value}"/>
                </el-form-item>
                <el-form-item :label="typeList[inputForm.menuType] + '名称'" prop="name">
                    <el-input maxlength="50" v-model="inputForm.name"
                              :placeholder="typeList[inputForm.menuType] + '名称'"></el-input>
                </el-form-item>
                <el-form-item label="链接地址" prop="href">
                    <el-input maxlength="1000" v-model="inputForm.href" placeholder="请填写路由路径或者超链接"></el-input>
                </el-form-item>
                <el-form-item v-if="inputForm.menuType === '0'" label="链接类型" prop="target">
                    <el-select v-model="inputForm.target" placeholder="如果是路由路径请留空白，http链接或者外部链接请选择iframe" clearable
                               style="width: 100%;">
                        <el-option
                                v-for="item in [{label: 'iframe', value: 'iframe'}]"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-if="inputForm.menuType === '0'" label="菜单图标" prop="icon">
                    <el-input v-model="inputForm.icon" clearable @focus="selectIcon" placeholder="菜单图标名称"></el-input>
                </el-form-item>
                <el-form-item v-if="inputForm.menuType === '0'" label="可见" prop="isShow">
                    <el-radio-group v-model="inputForm.isShow">
                        <el-radio label="0">显示</el-radio>
                        <el-radio label="1">隐藏</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="是否管理员可见" prop="menuSys">
                    <el-radio-group v-model="inputForm.menuSys">
                        <el-radio label="0">是</el-radio>
                        <el-radio label="1">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="授权标识"
                              prop="permission">
                    <el-input v-model="inputForm.permission" maxlength="50"
                              placeholder="多个用逗号分隔, 如: user:list,user:create"></el-input>
                </el-form-item>
                <el-form-item label="排序号" prop="sort">
                    <el-input-number v-model="inputForm.sort" controls-position="right" :min="0"
                                     label="排序号"></el-input-number>
                </el-form-item>
                <el-form-item label="备注" prop="remarks">
                    <el-input v-model="inputForm.remarks" maxlength="200" type="textarea"
                              :rows="2" placeholder="备注"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="text_center">
                <el-button size="small" @click="visible = false">{{method == 'view' ? '关闭' : '取消'}}</el-button>
                <el-button size="small" v-if="method != 'view'" type="primary" @click="doSubmit()" v-noMoreClick>保存</el-button>
            </div>
        </el-dialog>
        <Icon ref="icon" @getValue="value => inputForm.icon = value"></Icon>
    </div>
</template>

<script>
    import Icon from '@/components/icon'
    import SelectTree from '@/components/treeSelect/treeSelect.vue'

    export default {
        data() {
            const validateUrl = (rule, value, callback) => {
                if (this.inputForm.menuType === 0 && !/\S/.test(value)) {
                    callback(new Error('菜单URL不能为空'))
                } else {
                    callback()
                }
            }
            return {
                visible: false,
                loading: false,
                method: '',
                title: '新增',
                typeList: ['菜单', '按钮'],
                menuList: [],
                menuListTreeProps: {
                    label: 'name',
                    children: 'children'
                },
                inputForm: {
                    id: '', // 菜单id
                    name: '',  // 菜单名称
                    parentId: '1',  // 上级菜单id
                    href: '',  // 链接地址
                    target: '',  // 链接类型
                    icon: '',  // 菜单图标
                    sort: 1,  // 排序号
                    isShow: '0',  // 可见
                    menuSys: '1',  // 管理员可见
                    menuType: '0',  // 菜单类型
                    remarks: '',  // 备注
                    permission: '',  // 授权标识
                },
                dataRule: {
                    name: [
                        {required: true, message: '名称不能为空', trigger: 'blur'}
                    ],
                    url: [
                        {validator: validateUrl, trigger: 'blur'}
                    ]
                }
            }
        },
        components: {
            SelectTree,
            Icon
        },
        methods: {
            init(method, obj, row) {
                this.method = method
                this.inputForm.id = obj.id
                if (method === 'add') {
                    this.title = `新增`
                } else if (method === 'addChild') {
                    this.title = '添加下级'
                } else if (method === 'edit') {
                    this.title = '修改'
                } else if (method === 'view') {
                    this.title = '查看'
                }
                this.$axios(this.api.auth.sysnewmenuList, {}, 'get').then(data => {
                    this.menuList = data.data
                    this.inputForm.parentId = ''
                    this.inputForm.parentName = ''
                }).then(() => {
                    this.visible = true
                    this.$nextTick(() => {
                        this.$refs.menuParentTree.clearHandle()
                        this.$refs['inputForm'].resetFields()
                        this.inputForm.parentId = obj.parent.id
                        this.inputForm.parentName = obj.parent.name
                    })
                }).then(() => {
                    if (method === 'edit' || method === 'view') { // 修改或者查看
                        this.inputForm = this.recover(this.inputForm, row)
                    }
                })
            },
            selectIcon() {
                this.$refs.icon.visible = true
            },
            // 表单提交
            doSubmit() {
                this.$refs['inputForm'].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        let api = '', type = '';
                        if (this.method === 'add' || this.method === 'addChild') {  // 新增
                            api = this.api.auth.sysnewmenuSave;
                            type = 'post';
                        } else if (this.method === 'edit') {  // 修改
                            api = this.api.auth.sysnewmenuUpdateById
                            type = 'put';
                        }
                        if (!this.inputForm.parentId) {
                            this.inputForm.parentId = '1'
                        }
                        if (this.inputForm.menuType == '1') {
                            this.inputForm.target = '';
                            this.inputForm.icon = '';
                            this.inputForm.isShow = '0';
                        }
                        this.$axios(api, this.inputForm, type).then(data => {
                            this.loading = false
                            if (data && data.status) {
                                this.$message.success(data.msg)
                                this.visible = false
                                this.$emit('refreshDataList')
                            }
                        })
                    }
                })
            }
        }
    }
</script>
<style>

</style>
